import i18n from "@/plugins/i18n";
import { isUndefined, round } from "lodash";
import moment from "moment-timezone";

const FILE_MAX_FILE = 2000000;
const MAX_LENGTH = 255;
const MIN_LENGTH = 2;
const PRECISION: number = 8;
const FIELDS = {
	password: {
		length: 8,
	},
};

export const LIMIT_IMPRESIONS = 20000000;

const matchedCurrencyLimit = {
	USD: "60000.000",
	COP: "150000.000",
	ARS: "4000000.000",
	MXN: "2000000.000",
	CLP: "30000000.000",
	BRL: "700000.000",
};
const spend = 1000;
export function isNotZero(v: any) {
	return v != 0 || i18n.t("cannot-be-zero");
}

export function isArrayRequired(v: Array<any>) {
	return v.length > 0 || i18n.t("fieldRequired");
}

export function isRequired(v: any) {
	return Boolean(v) || i18n.t("fieldRequired");
}

export function isMustValidated(v: any) {
	return Boolean(v) || i18n.t("must-be-validated");
}

export function isNumber(v: any) {
	return (isUndefined(v) || !isNaN(v)) || i18n.t("must-be-numeric");
}
export function isInteger(v: any) {
	return (isUndefined(v) || !isNaN(v)) || i18n.t("integer");
}
export function isEmail(v: any) {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(v).toLowerCase()) || i18n.t("invalid-email");
}

export function isPasswordFormat(v: any) {
	const re =
		/^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{6,}$/;
	return re.test(String(v)) || i18n.t("invalid-password");
}

export function isMatchingPasswords(v: any, p: any) {
	return v == p || i18n.t('login.validate.matching');
}

export function isValidPassword(text: string) {

	if(!hasLength(text)) return i18n.t("minLength", { min: FIELDS.password.length });

	if(!hasDigit(text)) return i18n.t('login.validate.digit');

	if(!hasLower(text)) return i18n.t('login.validate.lower');

	if(!hasUpper(text)) return i18n.t('login.validate.upper');

	if(!hasSpecialChar(text)) return i18n.t('login.validate.special_character');

	return true;
}

export function isLoginPassed(email: string, password: string) {
	return passedEmail(email) && hasPassword(password);
}

export function isMinPassword(v: any) {
	return (
		(v && v.length >= FIELDS.password.length) ||
		i18n.t("minLength", { min: FIELDS.password.length })
	);
}

export function isMinLength(v: any) {
	return (
		(v && v.toString()?.length >= MIN_LENGTH) ||
		i18n.t("minLength", { min: MIN_LENGTH })
	);
}

export function isMaxLength(v: any) {
	return (
		(v && v.length <= MAX_LENGTH) ||
		i18n.t("maxLength", { max: MAX_LENGTH })
	);
}

export function isMaxCustomLength(v: any, limit: any) {
	return (
		(v && v.length <= limit) ||
		i18n.t("maxLength", { max: limit })
	);
}


export function isMin(v: any, min: number) {
	return (v && Number(v) >= min) || i18n.t("min", { min: min });
}

export function isMax(v: any, max: number) {
	return (v && Number(v) <= max) || i18n.t("max", { max: max });
}

export function isBetween(v: any, between: { min: number, max: number }) {
	return (v && Number(v) >= between.min) && (v && Number(v) <= between.max) || i18n.t("between", between);
}

export function isMaxByCurrency(v: any, currency: string) {
	const max = getLimitByCurrency(currency);
	const field = round(parseFloat(v), PRECISION);
	const limit = round(parseFloat(max), PRECISION);
	return (v && field <= limit) || i18n.t("max", { max: max });
}

export function isFileMaxSize(v: any) {
	return (
		(v && v.size <= FILE_MAX_FILE) ||
		i18n.t("file-max-size", { max: FILE_MAX_FILE / 1000000 })
	);
}

export function isFileType(v: File | null, types: Array<any> = []) {
	return types.includes(v?.type) || i18n.t("validations.svg");
}

export function isDomain(v: any) {
	return (
		/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:?#[\]@!\$&'\(\)\*\+,;=.]+(?<!\/)$/gim.test(
			v
		) || i18n.t("domain")
	);
}
export function isMaxBySpend(budget: string, limit: number) {
	return (
		parseFloat(budget) >= limit &&
		i18n.t("campaign.messages.currency-monto", { currency: limit })
	);
}
export function isCountry(v:any){
	return(
		i18n.t("targeting.geo.country")
	)
}
export function isDeal(){
	return(
		i18n.t("targeting.appSite.deal_id")
	)
}

export function isBeforeToday(v: any) {
	return moment(v).isBefore(moment()) || i18n.t("min-todate");
}

export function isAfterToday(v: any) {
	return moment(v).isAfter(moment()) || i18n.t("min-todate");
}

export function isSameOrBeforeToday(v: any) {
	const startDate = moment(v);
	const today = moment();
	return startDate.isSameOrBefore(today) || i18n.t("same-before-today");
}

export function isSameOrAfterToday(v: any) {
	const startDate = moment(v);
	const today = moment();
	return startDate.isSameOrAfter(today) || i18n.t("same-after-today");
}

export function isMaxDays(end_date: Date, start_date: Date, max_days: number) {
	const startDate = moment(start_date);
	const endDate = moment(end_date);
	const duration = moment.duration(endDate.diff(startDate));
	const days = Math.ceil(duration.asDays());
	return days <= max_days || i18n.t("max-days", { max: max_days });
}

export function isAfterCompare(v: any, compareDate: any) {
	return moment(v).isAfter(moment(compareDate)) || i18n.t("must-after-start");
}

export function isAfterCompareAndAfterToday(v: any, compareDate: any) {
	if (!moment(v).isAfter(moment(compareDate)) || !moment(v).isAfter(moment()))
		return `${i18n.t("must-after-start")}. ${i18n.t("min-todate")}.`;
	return true;
}

export function isValidUrl(v: any) {
	if (v === "") return false;
	return (
		/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gim.test(
			v
		) || i18n.t("domain")
	);
}

export function isValidUrlNotRequire(v: any) {
	if (v === "") return true;
	return (
		/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gim.test(
			v
		) || i18n.t("domain")
	);
}

export function isUploaded(v: any) {
	return !(v instanceof File) || i18n.t("uploaded");
}

/**
 * static function
 * @param v
 * @returns
 */
export function isUrl(v: any) {
	return /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gim.test(
		v
	);
}

/**
 * static function
 * @param v
 * @returns
 */
export function datesAreEquals(start: any, end: any) {
	if (start == null || start == undefined || typeof start != "string") {
		return false;
	}
	if (end == null || end == undefined || typeof end != "string") {
		return false;
	}
	return start.localeCompare(end) == 0;
}

export function isBToday(v: any) {
	return moment(v).isBefore(moment());
}

export function isToday(v: any) {
	const today = moment();
	return moment(v).isSame(today);
}

export function isPass(v: any) {
	return moment().diff(moment(v)) > 0;
}

export function adjustDate(v: any) {
	return !!v && !isPass(v) ? moment(v).format() : moment().format();
}

export function getLimitByCurrency(v: string) {
	return matchedCurrencyLimit[v];
}
export function getLimitByCurrencySpend() {
	return spend;
}
/**
 * Email rules
 */

/**
 * Has length
 * @param email
 * @returns
 */
export function passedEmail(email: string) {
	return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
}

/**
 * Password rules
 */

/**
 * Has password
 * @param text
 * @returns
 */
export function hasPassword(text: string) {
	return String(text).length > 0;
}

/**
 * Has length
 * @param text
 * @returns
 */
export function hasLength(text: string) {
	return String(text).length >= FIELDS.password.length;
}

/**
 * Has digit
 * @param text
 * @returns
 */
export function hasDigit(text: string) {
	return /[0-9]/.test(text);
}

/**
 * Has lowercase
 * @param text
 * @returns
 */
export function hasLower(text: string) {
	return /[a-z]/.test(text);
}

/**
 * Has uppercase
 * @param text
 * @returns
 */
export function hasUpper(text: string) {
	return /[A-Z]/.test(text);
}

/**
 * Has special character
 * @param text
 * @returns
 */
export function hasSpecialChar(text: string) {
	return /\W|_/.test(text);
}

/**
 * Is passed password
 * @param text
 * @returns
 */
export function isPasswordPassed(text: string) {
	return (
		hasLength(text) &&
		hasDigit(text) &&
		hasLower(text) &&
		hasUpper(text) &&
		hasSpecialChar(text)
	);
}

/**
 * Get Password length
 * @returns
 */
export function getPasswordLength() {
	return FIELDS.password.length;
}
